import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import SummaryTable from "components/SummaryTable";
import { useWizard } from "react-use-wizard";
import { useCheckoutContext } from "context/CheckoutContext";
import { FloatingLabel } from "react-bootstrap";

const Subscription = ({ cart }) => {
  const { nextStep } = useWizard();
  const { checkout, setCheckout } = useCheckoutContext();

  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setDate(minDate.getDate() + 30);

  const minDateString = `${minDate.getFullYear()}-${(
    "0" +
    (minDate.getMonth() + 1)
  ).slice(-2)}-${("0" + minDate.getDate()).slice(-2)}`;

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 60);
  const maxDateString = `${maxDate.getFullYear()}-${(
    "0" +
    (maxDate.getMonth() + 1)
  ).slice(-2)}-${("0" + maxDate.getDate()).slice(-2)}`;

  const handleSubmit = (event) => {
    event.preventDefault();

    // Proceed to the next step
    nextStep();
  };

  useEffect(() => {
    setCheckout({ ...checkout, currentStep: 2 });
  }, []);

  const {
    subscription: { frequency, startDate },
  } = checkout;

  const updateContext = (target, value) => {
    let subscriptionForm = checkout.subscription;
    subscriptionForm[target] = value;

    setCheckout({ ...checkout, subscription: subscriptionForm });
  };

  return (
    <div>
      <div className="wizardContainer">
        <button className="wizardLink">Cart</button>
        {">"}
        <button className="wizardLink">Account Details</button>
        {">"}
        <button className="wizardLink">Shipping Address</button>
        {">"}
        <button className="wizardLink">Subscription</button>
        {">"}
        <button className="wizardLink" disabled>
          Payment
        </button>
      </div>

      <SummaryTable
        contact={cart.customer.email}
        referral={cart.cart.referral}
      />

      <Row className="mb-3">
        <Col md="12">
          <h2 className="marginBottomSmall marginTopSmall">Subscription</h2>
          <Form onSubmit={handleSubmit}>
            {/* Your subscription form fields */}
            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="How often do you want to receive this?"
                className="mb-3"
              >
                <Form.Select
                  value={frequency}
                  onChange={(e) => updateContext("frequency", e.target.value)}
                  required
                >
                  <option value=""></option>
                  <option value="Monthly">Monthly</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="When should it start?"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => updateContext("startDate", e.target.value)}
                  min={minDateString}
                  max={maxDateString}
                  required
                />
              </FloatingLabel>
            </Form.Group>

            <div className="flex JCEnd">
              <Button
                className="btn-bold oola-main-color"
                variant="primary"
                type="submit"
              >
                Continue
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Subscription;
