//Mock
//URL: "https://m6zkg.wiremockapi.cloud/customers/authenticate"
//loginName: "test@com"
//password: "1"

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { API_LOCAL_BASE_URL } from "../../config";
import { Row } from "react-bootstrap";

export const LoginModal = ({ show, handleClose, handleLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${API_LOCAL_BASE_URL}api/Checkout/Login?username=${email}&password=${password}`,
      );
      if (response.ok) {
        const data = await response.json();
        handleLogin(data);
        setError("");
        handleClose();
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setError("An error occurred while logging in. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <FloatingLabel
              controlId="floatingInput"
              label="Email"
              className="mb-3"
            >
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Email is required
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="password">
            <FloatingLabel
              controlId="floatingInput"
              label="Password"
              className="mb-3"
            >
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FloatingLabel>
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <Row className="m-0">
            <Button className="btn-block" type="submit">
              Login
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
