import React, { useState } from "react";
import { useCheckoutContext } from "context/CheckoutContext";
import { recalculateCart } from "api";

const ShipMethods = ({ shipMethods }) => {
  const { checkout, setCheckout } = useCheckoutContext();
  const [shippingMethod, setShippingMethod] = useState("0");
  const [loading, setLoading] = useState(false);

  const updateCheckoutState = (updatedCart) => {
    setCheckout((prevCheckout) => ({
      ...prevCheckout,
      cart: {
        ...prevCheckout.cart,
        shipMethodID: updatedCart.shipMethodID,
        shippingTotal: updatedCart.shippingTotal,
      },
    }));
  };

  const handleRecalculateSuccess = (SelectedShipMethodId, updatedCart) => {
    updateCheckoutState(updatedCart);
    setShippingMethod(SelectedShipMethodId);
  };

  const handleRecalculateError = (error) => {
    console.error("Error:", error);
  };

  const recalculateShipmentOrder = async (e) => {
    try {
      setLoading(true);

      const SelectedShipMethodId = e.target.id;
      checkout.cart.shipMethodID = SelectedShipMethodId;

      // Call the API to recalculate shipment
      const updatedCart = await recalculateCart(checkout);

      handleRecalculateSuccess(SelectedShipMethodId, updatedCart);
    } catch (error) {
      handleRecalculateError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <></>
    /*<h2 className='marginBottomSmall marginTopSmall'>Shipmethod</h2>  
    <Col md='12'>
      {loading && (
        <div className="loading-container">
          <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div>Recalculating shipping...</div>
        </div>
      )}
       
      {!loading && shipMethods && shipMethods.map((method, index) => (
        <div key={index} className='box boxTop flex JCSpaceBetween'>
          <Form.Check
            name='shippingCost'
            type='radio'
            id={method.shipMethodID}
            label={method.shipMethodDescription.toUpperCase()}
            value={method.price}
            checked={shippingMethod === method.shipMethodDescription}
            onChange={recalculateShipmentOrder}
          />
          ${method.price}
        </div>
      ))}

      {!loading && (
        <div className='box boxMiddle flex JCSpaceBetween'>
          <Form.Check
            name='shipMethod'
            type='radio'
            id='6'
            value='0.00'
            label='LOCAL PICKUP'
            checked={shippingMethod === '6'}
            onChange={recalculateShipmentOrder}
          />
          $0.00
        </div>
      )}
    </Col>*/
  );
};

export default ShipMethods;
