const Footer = () => {
  return (
    <div className="marginTopSmall">
      <a
        className="policy-list__item"
        href="https://www.oolalife.com/privacyPolicy"
      >
        Privacy Policy
      </a>
      <a
        className="policy-list__item"
        href="https://myoola.oolalife.com/Content/docs/CustomerAgreement.pdf"
      >
        Customer Agreement
      </a>
    </div>
  );
};

export default Footer;
