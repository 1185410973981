import { useEffect, useState } from "react";
import { useCheckoutContext } from "context/CheckoutContext";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container } from "react-bootstrap";

const SearchReferral = ({ referral }) => {
  const [searching, setSearching] = useState(false);
  const { checkout, setCheckout } = useCheckoutContext();
  const { referredId } = checkout;
  const [referrals, setReferrals] = useState([]);
  const [referalSelected, setReferalSelected] = useState(
    referredId ? referredId : referral,
  );

  const fetchReferrals = async (value) => {
    /*try {
          setSearching(true);
          const response = await fetch(`${API_BASE_URL}/referrals`);
    
          if (response.ok) {
            const data = await response.json();
            setReferrals(data); // Store the fetched referrals in state

          } else {
            if (response.status === 404) throw new Error('404, Not found');
            if (response.status === 500) throw new Error('500, internal server error');
            throw new Error(response.status);
          }
        } catch (error) {
          console.error('Fetch', error);
        }  finally {
          setSearching(false);
        } */
  };
  const updateContext = (target, value) => {
    setReferalSelected(value);
    setCheckout({ ...checkout, [target]: value });
  };

  const handleSearching = () => {
    setSearching((prevSearching) => !prevSearching);
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  return (
    <>
      {searching ? (
        <Container className="container-fluid">
          <Row>
            <Col xs={9} md={10} style={{ marginLeft: "-0.5rem" }}>
              Referred by
            </Col>
            <Col xs={3} md={2}>
              <button
                onClick={handleSearching}
                className="text-right referredButton"
              >
                <u>Close</u>
              </button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12}>
              <FloatingLabel
                controlId="floatingInput"
                label="Search for name or ID."
                className="mb-3"
              >
                <Form.Select
                  onChange={(e) => {
                    updateContext("referredId", e.target.value);
                    handleSearching();
                  }}
                  value={referredId}
                >
                  {referrals.map((referral) => (
                    <option
                      key={referral.id}
                      id={referral.id}
                      value={referral.firstName + " (#" + referral.id + ")"}
                    >
                      {referral.firstName} (#{referral.id})
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row className="mb-3">
            <Col xs={3} md={3} style={{ marginLeft: "-0.5rem" }}>
              Referred by
            </Col>
            <Col xs={6} md={7} className="text-black">
              {referalSelected}
            </Col>
            <Col xs={2} md={2}>
              {/*<button onClick={handleSearching} className='referredButton'>
                    <u>Change</u>
                  </button>*/}
            </Col>
          </Row>
          <Row></Row>
        </Container>
      )}
    </>
  );
};

export default SearchReferral;
