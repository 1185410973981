import { useEffect } from "react";
import { useCheckoutContext } from "context/CheckoutContext";
import { Container } from "react-bootstrap";

const ConfirmOrderSuccess = () => {
  const { checkout, setCheckout } = useCheckoutContext();

  useEffect(() => {
    setCheckout({ ...checkout, currentStep: 4 });
  }, []);

  return (
    <div className="horizontalRuler">
      <Container className="p-0 w-75">
        <div className="mt-4 text-center">
          <h1 className="text-secondary-color">YOUR ORDER HAS BEEN PLACED</h1>
          <p>
            Thank you for your purchase. Your order has earned you{" "}
            <span class="fw-bolder"> {checkout.orderPoints} oola moola</span> to
            spend on future orders!
          </p>
          <p>
            Please review your order completion email for more details regarding
            your order.
          </p>
          <a
            variant="primary"
            href="https://oolalife.com/account/login?clearcart=true"
          >
            Login To Your Account
          </a>
        </div>
      </Container>
    </div>
  );
};

export default ConfirmOrderSuccess;
