import React from "react";
import { Wizard } from "react-use-wizard";
import AccountDetails from "components/AccountDetails";
import ShippingAddress from "components/ShippingAddress";
import Payment from "components/Payment";
import { GetStep } from "context/CheckoutContext";
import Subscription from "./Subscription";
import ConfirmOrderSuccess from "./ConfirmOrderSuccess";

const CheckoutWizard = ({ cart }) => (
  <Wizard startIndex={GetStep(cart)}>
    <AccountDetails customer={cart.customer} referral={cart.cart.referral} />
    {cart.shopifyCustomer.mainAddress !== undefined ? (
      <ShippingAddress
        addresses={[
          cart.shopifyCustomer.mainAddress,
          cart.shopifyCustomer.mailingShippingAddress,
          cart.shopifyCustomer.otherShippingAddress,
        ]}
        contact={cart.customer.email}
        referral={cart.cart.referral}
      />
    ) : (
      <ShippingAddress
        contact={cart.customer.email}
        referral={cart.cart.referral}
      />
    )}
    {cart.cart.autoOrderItems && cart.cart.autoOrderItems.length > 0 && (
      <Subscription cart={cart} />
    )}
    <Payment cart={cart} />
    <ConfirmOrderSuccess cart={cart} />
  </Wizard>
);

export default CheckoutWizard;
