import React, { useState } from "react"; // Import React and useState
import { Form } from "react-bootstrap"; // Import Form from your UI library

const EmailField = ({ updateContext }) => {
  // State to store the validation result
  const [isEmailValid, setIsEmailValid] = useState(true);

  // Function to handle email validation
  const validateEmail = async (email) => {
    setIsEmailValid(true);
    /* try {
      const response = await axios.post(
        `${API_LOCAL_BASE_URL}/api/Checkout/IsWebaliasAvailable`,
        {
          email: email,
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setIsEmailValid(data.available);
      } else {
        console.error('Failed to check email availability');
        setIsEmailValid(false);
      }
    } catch (error) {
      console.error('Error while checking email availability:', error);
      setIsEmailValid(false);
    }*/
  };

  return (
    <>
      <Form.Control
        type="email"
        onChange={(e) => {
          updateContext("email", e.target.value);
          setIsEmailValid(true);
        }}
        onBlur={(e) => validateEmail(e.target.value)}
        required
        isInvalid={!isEmailValid}
      />
      <Form.Control.Feedback type="invalid">
        Email is not available
      </Form.Control.Feedback>
    </>
  );
};

export default EmailField; // Export the component
