import React, { createContext, useContext, useState } from "react";

const CheckoutContext = createContext();

const defaultCheckoutState = {
  cartToken: "",
  shopUrl: "someURL",
  currentStep: 0,
  applyPoints: false,
  orderPoints: 0,
  shippingCost: "",
  sameShippingAddress: false,
  shippingAddressSameAsMain: false,
  billingAddressSameAsMain: false,
  // Checkout steps
  account: {
    email: "",
    firstName: "",
    lastName: "",
    homePhone: "",
    password: "",
    passwordConfirmation: "",
    preferredLanguage: "",
    birthDay: "",
    acceptMarketing: false,
    country: "",
    streetAddress: "",
    state: "",
    address2: "",
    city: "",
    zipcode: "",
    sameShippingAddress: false,
  },
  subscription: {
    frequency: "monthly",
    startDate: "",
  },
  shipping: {
    email: "",
    firstName: "",
    lastName: "",
    homePhone: "",
    country: "",
    state: "",
    streetAddress: "",
    city: "",
    zip: "",
    password: "",
    confirmPassword: "",
    loginEmail: "",
  },
  creditcard: {
    name: "",
    cardnumber: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  },
  billingAddress: {
    acceptedTerms: false,
    sameAsShipping: false,
    country: "",
    state: "",
    streetAddress: "",
    city: "",
    zipcode: "",
  },
  customer: {},
  shopifyCustomer: {},
  cart: {},
  customSettings: {},
};

export const GetStep = (cart) => {
  return cart.customerId === "" ? 0 : 1;
};

export const CheckoutContextProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultCheckoutState);

  return (
    <CheckoutContext.Provider value={{ checkout, setCheckout }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  return useContext(CheckoutContext);
};
