import Form from "react-bootstrap/Form";

const Select = ({ name, value, updateContext, collection, nestedName }) => {
  const USSTATE = [
    ["", ""],
    ["AA", "AE Military"],
    ["AE", "AE Military"],
    ["AK", "Alaska"],
    ["AL", "Alabama"],
    ["AP", "AP Military"],
    ["AR", "Arkansas"],
    ["AS", "American Samoa"],
    ["AZ", "Arizona"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DC", "D.C."],
    ["DE", "Delaware"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["GU", "Guam"],
    ["HI", "Hawaii"],
    ["IA", "Iowa"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["MA", "Massachusetts"],
    ["MD", "Maryland"],
    ["ME", "Maine"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MO", "Missouri"],
    ["MP", "Northern Mariana Islands"],
    ["MS", "Mississippi"],
    ["MT", "Montana"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["NE", "Nebraska"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NV", "Nevada"],
    ["NY", "New York"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["PR", "Puerto Rico"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UM", "United States Minor Outlying Islands"],
    ["US", "US"],
    ["UT", "Utah"],
    ["VA", "Virginia"],
    ["VI", "Virgin Islands"],
    ["VT", "Vermont"],
    ["WA", "Washington"],
    ["WI", "Wisconsin"],
    ["WV", "West Virginia"],
    ["WY", "Wyoming"],
  ];

  const COUNTRIES = [
    ["", ""],
    ["US", "United States"],
  ];

  const CARDMONTHS = [
    ["1", "1 - January"],
    ["2", "2 - February"],
    ["3", "3 - March"],
    ["4", "4 - April"],
    ["5", "5 - May"],
    ["6", "6 - June"],
    ["7", "7 - July"],
    ["8", "8 - August"],
    ["9", "9 - September"],
    ["10", "10 - October"],
    ["11", "11 - November"],
    ["12", "12 - December"],
  ];

  const cardYears = () => {
    let currentYear = new Date().getFullYear();

    const years = Array.from(
      { length: currentYear + 20 - currentYear + 1 },
      (value, index) => [currentYear + index, currentYear + index],
    );
    return years;
  };

  let iterableCollection;

  switch (collection) {
    case "country":
      iterableCollection = COUNTRIES;
      break;
    case "usState":
      iterableCollection = USSTATE;
      break;
    case "cardMonths":
      iterableCollection = CARDMONTHS;
      break;
    case "cardYears":
      iterableCollection = cardYears();
      break;
    default:
      return null;
  }

  return (
    <Form.Select
      aria-label="Default select example"
      className="payment-form-select"
      value={value}
      onChange={(e) => updateContext(name, e.target.value, nestedName)}
    >
      {iterableCollection &&
        iterableCollection.map((state) => (
          <option value={state[0]} key={state}>
            {state[1]}
          </option>
        ))}
    </Form.Select>
  );
};

export default Select;
