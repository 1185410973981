/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "components/Select";
import { useWizard } from "react-use-wizard";
import { GetStep, useCheckoutContext } from "../context/CheckoutContext";
import SummaryTable from "./SummaryTable";
import { LoginModal } from "./exigo/Login";
import EmailField from "./exigo/EmailAvailable";
import { validateAccount } from "api";

const AccountDetails = ({ customer, referral }) => {
  const [validated, setValidated] = useState(false);
  const { previousStep, nextStep, goToStep } = useWizard();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (form.checkValidity() === false) {
      form.checkValidity();
      event.stopPropagation();
    }

    validateAccount(checkout.account)
      .then((validateAccount) => {
        if (validateAccount) {
          setValidated(true);
          checkout.account.sameShippingAddress ? goToStep(2) : nextStep();
        } else {
          event.stopPropagation();
        }
      })
      .catch((error) => {
        console.error("Error validating account:", error);
      });
  };

  const { checkout, setCheckout } = useCheckoutContext();

  const handleLogin = (userData) => {
    closeLoginModal();
    updateContext("silentTokenID", userData.customerID);
    goToStep(GetStep(checkout));
  };

  const updateContext = (target, value) => {
    let newAccount = checkout.account;
    newAccount[target] = value;

    setCheckout({ ...checkout, account: newAccount });
  };

  useEffect(() => {
    setCheckout({ ...checkout, currentStep: 0 });
  }, []);

  const {
    firstName,
    lastName,
    homePhone,
    password,
    passwordConfirmation,
    preferredLanguage,
    birthDay,
    acceptMarketing,
    country,
    streetAddress,
    state,
    address2,
    city,
    zipcode,
    sameShippingAddress,
  } = checkout.account;

  return (
    <>
      <div className="wizardContainer">
        <button className="wizardLink" onClick={() => previousStep()}>
          Cart
        </button>
        `{">"}`
        <button className="wizardLink" onClick={() => goToStep(0)}>
          Account Details
        </button>
        `{">"}`
        <button className="wizardLink" onClick={() => nextStep()} disabled>
          Shipping Address
        </button>
        `{">"}`
        <button className="wizardLink" onClick={() => nextStep()} disabled>
          Payment
        </button>
      </div>

      <SummaryTable contact={customer.email} referral={referral} />

      <Row>
        <Col xs="12" md="5">
          <h2>Account Details</h2>
        </Col>
        <Col xs="12" md="7">
          <p>
            Already have an account? <a onClick={openLoginModal}>Log in</a>
            <LoginModal
              show={showLoginModal}
              handleClose={closeLoginModal}
              handleLogin={handleLogin}
            />
          </p>
        </Col>
      </Row>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="marginBottomSmall">
          <FloatingLabel label="Email" className="mb-3">
            <EmailField updateContext={updateContext} />
          </FloatingLabel>

          <Row>
            <Col xs="12" md="6">
              <FloatingLabel
                controlId="passwordId"
                label="Password"
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => updateContext("password", e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Password is required
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col xs="12" md="6">
              <FloatingLabel label="Password Confirmation" className="mb-3">
                <Form.Control
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) =>
                    updateContext("passwordConfirmation", e.target.value)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Password Confirmation is required
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <FloatingLabel label="First Name" className="mb-3">
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={(e) => updateContext("firstName", e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  First Name is required
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col xs="12" md="6">
              <FloatingLabel label="Last Name" className="mb-3">
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(e) => updateContext("lastName", e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Last Name is required
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <FloatingLabel label="Home Phone" className="mb-3">
            <Form.Control
              type="number"
              value={homePhone}
              onChange={(e) => updateContext("homePhone", e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Home Phone is required
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Preferred Language" className="mb-3">
            <Form.Select
              aria-label="Default select example"
              value={preferredLanguage}
              onChange={(e) =>
                updateContext("preferredLanguage", e.target.value)
              }
            >
              <option value="English">English</option>
              <option value="Español">Español</option>
              <option value="Chinese (Simplified)">Chinese (Simplified)</option>
              <option value="Chinese (Traditional)">
                Chinese (Traditional)
              </option>
            </Form.Select>
          </FloatingLabel>

          <h2>Your Birthday</h2>

          <Row>
            <Col xs="12" md="12">
              <FloatingLabel label="Day" className="mb-3">
                <Form.Control
                  type="date"
                  value={birthDay}
                  onChange={(e) => updateContext("birthDay", e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Day is required
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
          <Form.Check
            type="checkbox"
            label="ACCEPT MARKETING"
            checked={acceptMarketing}
            onChange={(e) => {
              updateContext("acceptMarketing", e.target.checked);
            }}
          />

          <h2 className="marginBottomSmall marginTopSmall">Address</h2>
          <div className="box">
            <FloatingLabel label="Country" className="mb-3">
              <Select
                value={country}
                name="country"
                collection="country"
                updateContext={updateContext}
              />
            </FloatingLabel>

            <FloatingLabel label="Street Address" className="mb-3">
              <Form.Control
                type="text"
                value={streetAddress}
                onChange={(e) => updateContext("streetAddress", e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Street Address is required
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel label="Address 2" className="mb-3">
              <Form.Control
                type="text"
                value={address2}
                onChange={(e) => updateContext("address2", e.target.value)}
              />
            </FloatingLabel>

            <FloatingLabel label="State" className="mb-3">
              <Select
                value={state}
                name="state"
                collection="usState"
                updateContext={updateContext}
              />
            </FloatingLabel>

            <Row>
              <Col xs="12" md="6">
                <FloatingLabel label="City" className="mb-3">
                  <Form.Control
                    type="text"
                    value={city}
                    onChange={(e) => updateContext("city", e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    City is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs="12" md="6">
                <FloatingLabel label="Zipcode" className="mb-3">
                  <Form.Control
                    type="text"
                    value={zipcode}
                    onChange={(e) => updateContext("zipcode", e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Zipcode is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Form.Check
              type="checkbox"
              label="SHIPPING ADDRESS SAME AS THIS ADDRESS"
              checked={sameShippingAddress}
              onChange={(e) => {
                updateContext("sameShippingAddress", e.target.checked);
              }}
            />
          </div>
        </div>
        <div className="flex JCEnd">
          <Button className="btn-bold" variant="primary" type="submit">
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AccountDetails;
